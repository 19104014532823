import * as Sentry from '@sentry/react';
import { lazy } from 'react';
import { Outlet, createBrowserRouter } from 'react-router';
import { RouterProvider } from 'react-router/dom';

import { RouterErrorBoundaryFallback500Redirect } from '../components/RouterErrorBoundary/RouterErrorBoundary';

import { RedirectTo404 } from './guards/RedirectTo404';
import { RACRouterProvider } from './providers/RACRouterProvider';

const PublicCalculator = lazy(() => import('../pages/public/calculator/PublicCalculator'));
const ErrorPage = lazy(() => import('../pages/public/shared/ErrorPage/ErrorPage'));

const sentryCreateBrowserRouter =
  // @ts-expect-error -- enabling strict mode
  process.env.NODE_ENV === 'production' ? Sentry.wrapCreateBrowserRouter(createBrowserRouter) : createBrowserRouter;

const router = sentryCreateBrowserRouter([
  {
    element: (
      <RACRouterProvider>
        <Outlet />
      </RACRouterProvider>
    ),
    ErrorBoundary: RouterErrorBoundaryFallback500Redirect,
    children: [
      {
        // todo: CLI-3281 split the results panel into a separate route
        path: '/:emissionId?/:childEmissionId?/:view?',
        element: <PublicCalculator />,
      },
      {
        path: '/400',
        element: <ErrorPage code="400" />,
      },
      {
        path: '/403',
        element: <ErrorPage code="403" />,
      },
      {
        path: '/404',
        element: <ErrorPage code="404" />,
      },
      {
        path: '/500',
        element: <ErrorPage code="500" />,
      },
      {
        path: '*',
        element: <RedirectTo404 />,
      },
    ],
  },
]);

export function RouterCalculator() {
  return <RouterProvider router={router} />;
}
